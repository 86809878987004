import AmenitiesSlider from "@components/AmenitiesSlider/AmenitiesSlider"
import { AreaguideMoreInfo } from "@components/AreaguideInfo/AreaguideInfo"
import BannerOffplan from "@components/BannerOffplan/BannerOffplan"
import BrochureModalWithTrigger from "@components/BrochureModal/BrochureModal"
import FloatingCTAShell from "@components/FloatingCTAShell/FloatingCTAShell"
import FloorPlans from "@components/FloorPlans/FloorPlans"
import Layout from "@components/Layout/Layout"
import OffplanAbout from "@components/OffplanAbout/OffplanAbout"
import OffplanBrochure from "@components/OffplanBrochure/OffplanBrochure"
import OffplanImages from "@components/OffplanImages/OffplanImages"
import OffplanNavBar from "@components/OffplanNavBar/OffplanNavBar"
import PaymentPlans from "@components/PaymentPlans/PaymentPlans"
import RegisterInterestModalWithTrigger from "@components/RegisterInterestModal/RegisterInterestModal"
import RegisterInterestModule from "@components/RegisterInterestModule/RegisterInterestModule"
import TileBlock from "@components/TileBlock/TileBlock"
import VideoBanner from "@components/VideoBanner/VideoBanner"
import Seo from "@components/seo"
import useHasScrolledBanner from "@hooks/useHasScrolledBanner"
import { graphql, navigate } from "gatsby"
import React, { useEffect } from "react"
import { Container } from "react-bootstrap"

function OffplanTemplate({ data }) {
  const offplan = data?.strapiOffPlan
  
  if (!offplan) return null
  if (offplan?.publish === false) {
    if (typeof window !== "undefined") {
    navigate("/new-projects/", { replace: true })
    }
  }
  const scrolled = useHasScrolledBanner()
  const {
    characteristics_module,
    ggfx_results,
    strapi_id,
    location_tile,
    amenities,
    video_module,
  } = offplan

  useEffect(() => {
    if (typeof window !== "undefined") {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({'product_id': data?.strapiOffPlan?.strapi_id });
    }
  }, [data?.strapiOffPlan]);

  return (
    <>
    <Layout
      pageData={{
        page_class: "team-detail-page",
        layout: "team-detail",
      }}
      transparentHeader
    >
      <BannerOffplan offplan={offplan} />
      <OffplanNavBar offplan={offplan} />
      <OffplanAbout offplan={offplan} />
      <OffplanImages offplan={offplan} />
      <TileBlock
        module={characteristics_module}
        ggfx_results={ggfx_results}
        strapiID={strapi_id}
        className="characteristics-module"
        imagename="off-plan.characteristics_module_image.tile_img"
      />
      <FloorPlans offplan={offplan} />
      <AmenitiesSlider amenities={amenities} />
      <TileBlock
        module={location_tile}
        ggfx_results={ggfx_results}
        strapiID={strapi_id}
        className="location-module"
        id="offplan-location"
        imagename="off-plan.location_tile_image.tile_img"
      />
      {offplan.payment_plans?.length > 0 &&
      <PaymentPlans offplan={offplan} />}
      <OffplanBrochure offplan={offplan} />
      <Container
        className="video-banner-container section-l-m"
        id="offplan-video"
      >
        <VideoBanner
          bannerVideo={video_module}
          ggfx_results={ggfx_results}
          strapiID={strapi_id}
          imagename="off-plan.video_module_thumbnail.thumbnail"
        />
      </Container>
      <AreaguideMoreInfo more_info={offplan.more_info} title={offplan.title} />
      <RegisterInterestModule offplan={offplan} />
    </Layout>
    {scrolled &&
    <FloatingCTAShell>
<div className="cta-section">
          <BrochureModalWithTrigger offplan={offplan} />
          <RegisterInterestModalWithTrigger offplan={offplan} />
        </div>
    </FloatingCTAShell>}
    </>
  )
}

export default OffplanTemplate

export const Head = ({ data }) => {
  const offplan = data.strapiOffPlan

  if (!offplan) return null

  const building = offplan?.building_type?.strapi_json_value?.[0] || "property"
  const action = "for sale"
  const bedrooms = offplan?.max_bedrooms || offplan?.min_bedrooms
  const bedroomsText = bedrooms > 1 ? "bedrooms" : "bedroom"
  const title = offplan?.title
  const price = offplan?.price

  let showPrice = true

  if (!price || Number(price) === 0 || price === "N/A") {
    showPrice = false
  }

  const titleText = `Off plan ${building} ${action} with ${bedrooms} ${bedroomsText} in ${title}${
    showPrice ? ` at AED ${price}` : ""
  }`
  const descriptionText = `Know the details of off plan ${building} ${action} with ${bedrooms} ${bedroomsText} in ${title}${
    showPrice ? ` at AED ${price}` : ""
  }. Book a viewing to get assistance in finding the right ${building} for you.`

  const metaTitle = offplan?.seo?.metaTitle || titleText
  const metaDescription = offplan?.seo?.metaDescription || descriptionText
  const imgUrl = offplan?.images?.strapi_json_value?.length> 0 ? offplan?.images?.strapi_json_value[0]?.srcUrl : offplan?.media_images?.length > 0 ? offplan?.media_images?.url : offplan?.tile_image?.url
  return (
    <Seo
      title={metaTitle}
      description={metaDescription}
      img={imgUrl}
    />
  )
}

export const query = graphql`
  query ($id: String) {
    strapiOffPlan(id: { eq: $id }) {
      ...offplanFragment
    }
  }
`
