// import RegisterInterestForm from "@components/forms/RegisterInterestForm"
import CloseIcon from "@components/icons/CloseIcon"
import loadable from "@loadable/component"
import React, { useState } from "react"
import { Col, Container, Modal, Row } from "react-bootstrap"
import "./RegisterInterestModal.scss"
import { Link, graphql, useStaticQuery } from "gatsby"
import { parseHTML } from "@lib/utils"
import GGFXImage from "@components/elements/GGFXImage"
const RegisterInterestForm = loadable(() =>
  import("@components/forms/RegisterInterestForm")
)

export const RegisterInterestModal = ({ offplan, show, handleClose }) => {
  const data = useStaticQuery(graphql`
    query {
      strapiGlobalModule {
          offplan_popup_team {
            strapi_id
            ggfx_results {
              ...STRAPI_GGFX_RESULTFragment
            }
            designation
            phone
            slug
            office_phone
            name
            image {
              url
            }
          }
          offplan_popup_content {
            data {
              offplan_popup_content
            }
          }
      }
    }
  `)
  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      backdrop="static"
      className="register-interest-modal"
      // size="xl"
    >
      <Modal.Body className="register-interest-modal-body">
         <Container>
        {/*  <Row>
    <Col md={5}> */}
        <div className="reg-new-bk">
          <div className="reg-left">
          <div className="reg-new">
            {parseHTML(data?.strapiGlobalModule?.offplan_popup_content?.data?.offplan_popup_content)}
          </div>
          <div className="bottom-section">
          <div className="img-section">
            <GGFXImage
          ImageSrc={data?.strapiGlobalModule?.offplan_popup_team?.image}
          altText={data?.strapiGlobalModule?.offplan_popup_team?.name}
          imagetransforms={data?.strapiGlobalModule?.offplan_popup_team?.ggfx_results}
          renderer="srcSet"
          imagename="team.image.tile_img"
          strapiID={data?.strapiGlobalModule?.offplan_popup_team?.strapi_id}
        /></div>
            <div className="nego-info">
              <div>
              <p className="name">{data?.strapiGlobalModule?.offplan_popup_team?.name}</p>
              <p className="designation">{data?.strapiGlobalModule?.offplan_popup_team?.designation}</p>
              <a className="orn-no" href={`tel:${data?.strapiGlobalModule?.offplan_popup_team?.office_phone}`}>{data?.strapiGlobalModule?.offplan_popup_team?.office_phone}</a>
              </div>
            </div>
          </div>
</div>
        
        {/* </Col>
          <Col md={1}></Col>
          <Col md={6}> */}
        <div className="reg-form">
          <div className="register-interest-modal-header">

            {/* <div className="content-section">
            <h3 className="title">Register Interest</h3>
            <p className="description">
              Please fill in your details below to register your interest.
            </p>
          </div> */}
            <CloseIcon color="white" onClick={handleClose} />
          </div>
          <RegisterInterestForm offplan={offplan} noScrollOnSuccess />
        </div>
        </div>
        {/* </Col>
        </Row> */}
        </Container>

      </Modal.Body>
    </Modal>
  )
}

const RegisterInterestModalWithTrigger = ({
  offplan,
  buttonText,
  children,
  className,
}) => {
  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  // if (!offplan?.brochure?.file?.url) return null
  return (
    <>
      <button
        className={className || "button button-gray trigger-button"}
        onClick={handleShow}
      >
        {children || <span>{buttonText || "Register Interest"}</span>}
      </button>
      <RegisterInterestModal
        offplan={offplan}
        show={show}
        handleClose={handleClose}
      />
    </>
  )
}

export default RegisterInterestModalWithTrigger
