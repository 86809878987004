import { getIconColor } from "@lib/utils"
import clsx from "clsx"
import React from "react"

const ArrowRight = ({ className, color, ...props }) => {
  const stroke = getIconColor(color)

  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={clsx("arrow-right-icon", className)}
      {...props}
    >
      <path
        d="M9.5 3L14.5 8M14.5 8L9.5 13M14.5 8H2.5"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default ArrowRight
