// import BrochureForm from "@components/forms/BrochureForm"
import CloseIcon from "@components/icons/CloseIcon"
import React, { useState } from "react"
import { Modal } from "react-bootstrap"
import "./BrochureModal.scss"
import loadable from "@loadable/component"

const BrochureForm = loadable(() => import("@components/forms/BrochureForm"))

export const BrochureModal = ({ offplan, show, handleClose }) => {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      className="team-contact-modal"
    >
      <Modal.Body className="team-contact-modal-body">
        <div className="team-contact-modal-header">
          <div className="content-section">
            <h3 className="title">Download Brochure</h3>
            <p className="description">
              Please fill in your details below to download the brochure.
            </p>
          </div>
          <CloseIcon onClick={handleClose} />
        </div>
        <BrochureForm offplan={offplan} />
      </Modal.Body>
    </Modal>
  )
}

const BrochureModalWithTrigger = ({
  offplan,
  buttonText,
  children,
  className,
}) => {
  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  if (!offplan?.brochure?.file?.url && !offplan?.brochure?.url) return null
  return (
    <>
      <button
        className={className || "button button-orange trigger-button"}
        onClick={handleShow}
      >
        {children || <span>{buttonText || "Download Brochure"}</span>}
      </button>
      <BrochureModal offplan={offplan} show={show} handleClose={handleClose} />
    </>
  )
}

export default BrochureModalWithTrigger
