import React from "react"
import "./RegisterInterestModule.scss"
import { Container } from "react-bootstrap"
import { Link, graphql, useStaticQuery } from "gatsby"
import { parseHTML } from "@lib/utils"
import { teamsURL } from "@lib/urls"
// import RegisterInterestForm from "@components/forms/RegisterInterestForm"
import loadable from "@loadable/component"
import GGFXImage from "@components/elements/GGFXImage"

const RegisterInterestForm = loadable(() =>
  import("@components/forms/RegisterInterestForm")
)

const RegisterInterestModule = ({ offplan }) => {
  const data = useStaticQuery(graphql`
    query {
      strapiGlobalModule {
        ggfx_results {
          ...STRAPI_GGFX_RESULTFragment
        }
        strapi_id
        register_interest_form {
          bg_image {
            url
          }
          description {
            data {
              description
            }
          }
          strapi_id
          title
          
          # team {
          #   ...teamFragment
          # }
        }
      }
    }
  `)

  const module = data.strapiGlobalModule.register_interest_form

  if (!module) return null

  const { bg_image, description, title, team } = module
  return (
    <div
      className="register-interest-module-wrap section-l-p"
      id="register-interest"
    >
      <div className="bg-section">
        {/* <img src={bg_image.url} alt="bg" /> */}
        <GGFXImage
            ImageSrc={bg_image}
            altText={title}
            imagetransforms={data.strapiGlobalModule?.ggfx_results}
            renderer="srcSet"
            imagename="global-module.bg_image.bg_img"
            strapiID={data.strapiGlobalModule?.strapi_id}
          />
        <div className="overlay" />
      </div>
      <Container className="register-interest-module-container">
        <div className="left-section">
          <h2 className="title">{title}</h2>
          <div className="description">
            {parseHTML(description?.data?.description)}
          </div>
          {team && (
            <div className="team-card">
              <Link to={`${teamsURL + team.slug}/`} className="image-section">
                <img src={team.image?.url} alt="team-image" />
              </Link>
              <div className="content-section">
                <h3 className="name">{team.name}</h3>
                <p className="position">{team.designation}</p>
                <p className="license">ORN No: {team.license}</p>
              </div>
            </div>
          )}
        </div>
        <div className="right-section">
          <RegisterInterestForm offplan={offplan} />
        </div>
      </Container>
    </div>
  )
}

export default RegisterInterestModule
