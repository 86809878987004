import { formatNumber, parseHTML } from "@lib/utils"
import React from "react"
import { Container } from "react-bootstrap"
import "./OffplanAbout.scss"
import OffCurrencyPrice from "@components/CurrencyPrice/OffCurrencyPrice"

const OffplanAbout = ({ offplan }) => {
  const {
    about,
    price,
    display_price,
    completion_year,
    payment_plan_text,
    currency,
  } = offplan

  // if completion year's december is less than current month, then it is ready
  const isReady = completion_year
    ? new Date(new Date(completion_year)?.setMonth(12)) < new Date()
    : false

  return (
    <div className="about-offplan-wrap section-l-m" id="offplan-details">
      <Container className="about-offplan-container">
        <div className="left-section">
          <p className="heading">About the project</p>
          <div className="content">{parseHTML(about?.data?.about)}</div>
        </div>
        <div className="right-section">
          {price !== "TBA" ?(
            <div className="item-wrap">
              <p className="heading">Starting Price</p>
              <p className="content">
                {/* {currency || "AED"} {display_price || formatNumber(price)} */}
                <OffCurrencyPrice price={price} />
              </p>
            </div>
          ):
          <div className="item-wrap">
              <p className="heading">Starting Price</p>
              <p className="content">
              TBA
              </p>
            </div>
          }
          {completion_year && (
            <div className="item-wrap">
              <p className="heading">Handover</p>
              <p className="content">{isReady ? "Ready" : completion_year}</p>
            </div>
          )}
          {payment_plan_text && (
            <div className="item-wrap">
              <p className="heading">Payment Plan</p>
              <p className="content">{payment_plan_text}</p>
            </div>
          )}
        </div>
      </Container>
    </div>
  )
}

export default OffplanAbout
