import BrochureModalWithTrigger from "@components/BrochureModal/BrochureModal"
import GGFXImage from "@components/elements/GGFXImage"
import { ImageSlider } from "@components/elements/Slider/Slider"
import useDeviceMedia from "@hooks/useDeviceMedia"
import FsLightbox from "fslightbox-react"
import React, { useState } from "react"
import { Container } from "react-bootstrap"
import "./OffplanImages.scss"
import { isEmpty } from "lodash"
import clsx from "clsx"
const {
  FormCdnUrl,
} = require("@starberry/gatsby-theme-utils/Common/Stb-Jamstack-Ggfxclient/Utility")

const Image = ({
  image,
  index,
  setSelectedImage,
  setToggler,
  toggler,
  title,
  processedImages,
  strapiID,
}) => {
  const [isPointMoved, setIsPointMoved] = useState(false)

  const sliderOnSwipe = {
    onPointerDown: (e) => {
      if (e.buttons === 2) return
      setIsPointMoved(false)
    },
    onPointerMove: (e) => {
      if (e.buttons === 2) return
      setIsPointMoved(true)
    },
    onPointerUp: (e) => {
      if (isPointMoved || e.buttons === 2) return

      setToggler(!toggler)
      setSelectedImage(index)
    },
  }

  return (
    <div className="image-item img-zoom" key={image.url} {...sliderOnSwipe}>
      {image?.url ?
      <GGFXImage
        ImageSrc={image}
        altText={`${title} image ${index}`}
        imagetransforms={processedImages}
        renderer="srcSet"
        imagename="off-plan.images.gallery_image"
        strapiID={strapiID}
      />
      :
      <img src={image.srcUrl} alt={`${title} image ${index}`} />}
    </div>
  )
}

const DesktopImages = (props) => {
  const { images, imagesToDisplay } = props

  let gridClass = ""

  switch (images.length) {
    case 3:
      gridClass = "images-grid-3"
      break
    case 4:
      gridClass = "images-grid-4"
      break
    case 5:
      gridClass = "images-grid-5"
      break
    default:
      gridClass = ""
  }

  if (images.length <= 2) {
    return (
      <ImageSlider>
        {images.map((image, index) => (
          <Image key={image.url} image={image} index={index} {...props} />
        ))}
      </ImageSlider>
    )
  }
  return (
    <div className={clsx("images-grid", gridClass)}>
      {images.slice(0, imagesToDisplay).map((image, index) => (
        <Image key={image.url} image={image} index={index} {...props} />
      ))}
    </div>
  )
}

const OffplanImages = ({ offplan }) => {
  // console.log(offplan)
  const imageSliderRef = React.useRef(null)

  const { media_images, images, ggfx_results, title, strapi_id } = offplan

 
  const [toggler, setToggler] = React.useState(false)
  const [selectedImage, setSelectedImage] = React.useState(0)
  const imageUrls = media_images?.length > 0 ? media_images?.map((image) => FormCdnUrl(image.url)) : images?.strapi_json_value?.length > 0 ? images?.strapi_json_value?.map((image) => image?.url ? FormCdnUrl(image?.url) : image?.srcUrl) :  media_images?.map((image) => FormCdnUrl(image.url))

  const { isMobile, isTablet } = useDeviceMedia()
  const display_images = media_images?.length > 0 ? media_images : images?.strapi_json_value?.length > 0 ? images?.strapi_json_value : media_images
  // eslint-disable-next-line no-nested-ternary
  const imagesToDisplay = isMobile ? 1 : isTablet ? 2 : 6

  if (isEmpty(display_images)) return null

  const AllImageButton = (
    <button
      onClick={() => {
        setToggler(!toggler)
      }}
      className="button button-white all-image-button"
    >
      All Images
    </button>
  )

  return (
    <div className="offplan-images-wrap section-l-m" id="offplan-gallery">
      <Container className="offplan-images-container">
        <div className="images-grid-wrap">
          {isTablet ? (
            <>
              <ImageSlider ref={imageSliderRef}>
                {display_images?.slice(0, 12).map((image, index) => (
                  <Image
                    key={image.url}
                    image={image}
                    index={index}
                    setSelectedImage={setSelectedImage}
                    toggler={toggler}
                    setToggler={setToggler}
                    title={title}
                    processedImages={ggfx_results}
                    strapiID={strapi_id}
                  />
                ))}
              </ImageSlider>
              {AllImageButton}
            </>
          ) : (
            <DesktopImages
              images={display_images}
              imagesToDisplay={imagesToDisplay}
              setSelectedImage={setSelectedImage}
              toggler={toggler}
              setToggler={setToggler}
              title={title}
              processedImages={ggfx_results}
              strapiID={strapi_id}
            />
          )}
          <div className="trigger-button">
            <BrochureModalWithTrigger
              buttonText="Download 4K Images"
              className="button button-gray brochure-button"
              offplan={offplan}
            />
            {!isTablet && AllImageButton}
          </div>
        </div>
      </Container>
      <FsLightbox
        toggler={toggler}
        sources={imageUrls}
        sourceIndex={selectedImage}
        type="image"
      />
    </div>
  )
}

export default OffplanImages
